export default {
  name: 'RjBaseReferral',

  render(h) {
    return h('router-view', {
      props: {
        company: this.company,
        survey: this.survey,
        contact: this.contact,
        funnel: this.funnel,
      },
    });
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasPositiveSentiment() {
      return this.survey.sentiment === 1;
    },

    hasNegativeSentiment() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment === 0 && status === 'Completed' && feedback === null;
    },

    hasFeedback() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment !== null && status === 'Feedback' && feedback !== null;
    },

    isCompleted() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment !== null && status !== 'Visited' && feedback !== null;
    },
  },

  watch: {
    '$route.params.code': {
      handler: 'guard',
      immediate: true,
    },
  },

  methods: {
    guard() {
      if (this.isCompleted) {
        return this.redirectTo('completed-referral');
      }

      if (this.hasPositiveSentiment) {
        return this.redirectTo('offer-referral');
      }

      if (this.hasNegativeSentiment && !this.hasFeedback) {
        return this.redirectTo('negative-testimonial');
      }

      return this.redirectTo('referral');
    },

    redirectTo(name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name });
      }
    },
  },
};
